import React from "react"
// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import HubspotForm from "components/hubspot"

// BG
import HeroBg from "assets/images/homepage/hero/background-purple.png"


const SexualHealthForm = styled.section`
display: block;
flex-wrap: wrap;
flex-direction: row;
border-bottom-width: 0;
overflow: hidden;
background: url(${HeroBg});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
justify-content: center;
max-width: 1500px;
margin: 0 auto;
padding: 0;

${breakpoint.small`
flex-wrap: nowrap;
flex-direction: row;
`}

${breakpoint.medium`
margin-bottom: 60px;
`}

.imageBlock {
  position: relative;
  align-self: self-end;
}

.textBlock {
  padding: 50px 15px 20px;
  text-align: center;
  margin-top: 40px;

  h1 { 
    font-size: 50px;
    color: ${colors.white};
    font-weight: 400!important;
    line-height: 1.1em;
    text-align: center;

    ${breakpoint.small`
      font-size: 55px;
    `}

    ${breakpoint.large`
      font-size: 62px;
    `}
  }

  p { 
    color: ${colors.white};
    font-size: 18px;
    line-height: 1.2em;
    margin-block: 1rem;
    text-align: center;
    
    ${breakpoint.small`
      font-size: 20px;
    `}

    ${breakpoint.large`
      font-size: 22px;
    `}
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${breakpoint.small`
    flex-wrap: nowrap;
  `}
}

.heroTop {
  flex-wrap: wrap;
  margin: 0 auto !important;
  justify-content: center;
}

.column1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column2 {
  display: flex;
  flex-direction: column;
  padding: 0 10px 60px 20px;
  align-self: flex-start;
  width: 90%;
  margin: 0 auto;

  ${breakpoint.small`
    width: 70%;
    margin: 0;
  `}

  ${breakpoint.medium`
    width: 50%;
    padding: 20px 20px 0 60px;
  `}

  ${breakpoint.large`
    width: 45%;
    padding: 20px 20px 40px 60px;
  `}


p { 
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2em;
  margin-block: 1.4em 0;
  text-align: center;

  ${breakpoint.small`
  text-align: center;
    font-size: 18px;
  `}

}

.google__privacy {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4em;
  margin: 12px auto 0px;
  color: ${colors.white};
  width: 90%;
}

  a {
    color: ${colors.orange};

    &:hover {
      color: ${colors.charcoal};
    }
  }


}



`

const ContactForm = props => {

  return (
    <SexualHealthForm id="form">
      <div className="row heroTop">
        <div className="column1">
          <div className="textBlock">
            <h1>The power of PCR in your hands.</h1>
            <p>Get point-of-care STI results in under 30 minutes.</p>
          </div>
        </div>
        <div className="column2">
          <p>Schedule a call</p>
          <HubspotForm
            formId='fe2d9535-8456-479e-858a-12b70becee45'
          />
          <p className="google__privacy">
            This site is protected by reCAPTCHA and the
            Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy
              Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms
                of Service</a> apply.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="column1">
          <div className="imageBlock">
            <StaticImage
              src="../../../assets/images/homepage/hero/sexual-health-test.png"
              width={800}
              alt="Visby Medical Sexual Health Test"
            />
          </div>
        </div>

        <div className="column1">
          <div className="imageBlock">
            <StaticImage
              src="../../../assets/images/homepage/hero/respiratory-health-test.png"
              width={800}
              alt="Visby Medical Respiratory Health Test"
            />
          </div>
        </div>
      </div>
    </SexualHealthForm>
  )
}

export default ContactForm
